export const intl = {
  ru: {
    age: 'Возраст',
    more: 'еще',
    back: 'Назад',
    developer: 'Разработчик',
    developerResponse: 'Ответ разработчика',
    downloadApp: 'Загрузить',
    ratingLabel: 'Рейтинг',
    langLabel: 'Язык',
    info: {
      age: 'Возраст',
      category: 'Категория',
      compatibility: 'Совместимость',
      currentLang: 'Русский',
      languages: 'Языки',
      provider: 'Провайдер',
      size: 'Размер',
      sizeUnit: 'мб',
      title: 'Информация',
      worksOnDevice: 'Работает на этом iPhone',
    },
    rating: {
      all: 'См. все',
      outOf: 'из 5',
      title: 'Оценки и отзывы',
    }
  },
  en: {
    age: 'Age',
    more: 'more',
    back: 'Back',
    developer: 'Developer',
    developerResponse: 'Developer Response',
    downloadApp: 'Get',
    ratingLabel: 'Rating',
    langLabel: 'Language',
    info: {
      age: 'Age Rating',
      category: 'Category',
      compatibility: 'Compatibility',
      currentLang: 'English',
      languages: 'Languages',
      provider: 'Provider',
      size: 'Size',
      sizeUnit: 'mb',
      title: 'Information',
      worksOnDevice: 'Works on this iPhone',
    },
    rating: {
      all: 'See All',
      outOf: 'out of 5',
      title: 'Ratings & Reviews',
    }
  },
  es: {
    age: 'Edad',
    more: 'Más',
    back: 'Atrás',
    developer: 'desarrollador',
    developerResponse: 'Respuesta del desarrollador',
    downloadApp: 'OBTENER',
    ratingLabel: 'valoración',
    langLabel: 'idioma',
    info: {
      age: 'Edad',
      category: 'Categoría',
      compatibility: 'Compatibilidad',
      currentLang: 'Español',
      languages: 'Idiomas',
      provider: 'Proveedor',
      size: 'Tamaño',
      sizeUnit: 'MB',
      title: 'Información',
      worksOnDevice: 'Works on this iPhone',
    },
    rating: {
      all: 'valoración',
      outOf: 'de 5',
      title: 'Valoraciones y reseñas',
    }
  },
}
