import React, { useRef, useState, useEffect } from "react"
import { intl } from "../../intl/intl"

const ReviewDev = ({ data, lang, currentReviewIndex, onClick }) => {
  const textContainer = useRef(null)
  const maxLines = 2
  const [numberOfLines, setNumberOfLines] = useState(0)

  const moreBtnTpl = (
    <span className="review__more" onClick={() => onClick(currentReviewIndex)}>
      {intl[lang].more}
    </span>
  )

  useEffect(() => {
    setNumberOfLines(textContainer.current.getClientRects().length)
  }, [])

  return (
    <>
      <div className="review__head review__head--dev">
        <div className="review__head-row review__head-row--1">
          <div className="review__title">{intl[lang].developerResponse}</div>
          <div className="review__date">{data.Review_dev_date}</div>
        </div>
      </div>
      <div className="review__content review__content--dev">
        {/* Inline container for counting lines */}
        <span ref={textContainer}>{data.Review_dev_content}</span>
        {numberOfLines > maxLines && moreBtnTpl}
      </div>
    </>
  )
}

export default ReviewDev
