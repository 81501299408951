import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import {
  ComponentIsActiveContextProvider,
  TestFlightDataContextProvider,
} from "../context/context"

export const query = graphql`
  query AsoPageQuery($id: Int!) {
    strapiAso(strapiId: { eq: $id }) {
      strapiId
      ASO_images {
        url
        mime
      }
      Age
      App_icon {
        publicURL
      }
      Category
      Description
      Developer_full
      Developer_short
      Download_link
      facebook_pixel_id
      Footer
      google_analytics_id
      google_optimize_id
      GTM
      gtm_special_event
      Language
      Position
      Rating
      Rating_chart {
        five_stars
        four_stars
        one_star
        three_stars
        two_stars
      }
      Review {
        Review_dev_content
        Review_dev_date
        Review_rating
        Review_title
        Review_user
        Review_user_content
        Review_user_date
      }
      Reviews_num_short
      Rewiews_num_full
      scripts
      Size
      Subtitle
      Title
      Type
      Tutorial {
        Step_title
        Step_image {
          publicURL
        }
      }
      URL
    }
  }
`

const asoPage = ({ data }) => {
  const {
    ASO_images,
    Age,
    App_icon,
    Category,
    Description,
    Developer_full,
    Developer_short,
    Download_link,
    facebook_pixel_id,
    Footer,
    google_analytics_id,
    google_optimize_id,
    GTM,
    gtm_special_event,
    Language,
    Position,
    Rating,
    Rating_chart,
    Review,
    Reviews_num_short,
    Rewiews_num_full,
    scripts,
    Size,
    Subtitle,
    Title,
    Type,
    Tutorial,
    URL,
  } = data.strapiAso

  return (
    <TestFlightDataContextProvider>
      <ComponentIsActiveContextProvider>
        <Layout
          aso_images={ASO_images}
          age={Age}
          app_icon={App_icon}
          category={Category}
          description={Description}
          developer_full={Developer_full}
          developer_short={Developer_short}
          download_link={Download_link}
          facebook_pixel={facebook_pixel_id}
          footer={Footer}
          google_analytics={google_analytics_id}
          google_optimize={google_optimize_id}
          gtm={GTM}
          gtm_special_event={gtm_special_event}
          lang={Language}
          position={Position}
          rating={Rating}
          rating_chart={Rating_chart}
          reviews={Review}
          reviews_num_short={Reviews_num_short}
          reviews_num_full={Rewiews_num_full}
          scripts={scripts}
          size={Size}
          subtitle={Subtitle}
          title={Title}
          tutorial={Tutorial}
          type={Type}
          url={URL}
        />
      </ComponentIsActiveContextProvider>
    </TestFlightDataContextProvider>
  )
}

export default asoPage
