import React, { useState, useEffect, useContext } from "react"
import { ComponentIsActiveContext } from "../../context/context"
import logEvent from "../../utils/logEvent"
import Button from "../Button/Button"
import "./LoaderApple.css"
import appleLogo from "../../assets/images/apple-logo.svg"
import IconReady from "../../assets/images/icon-ready.png"

export default ({ gtm_special_event, url }) => {
  const { componentIsActive, setComponentIsActive } = useContext(
    ComponentIsActiveContext
  )
  const [isDownloaded, setIsDownloaded] = useState(false)
  const onClickHandler = () => {
    logEvent("click", { url, button: "go-to-instruction" }, gtm_special_event)

    setComponentIsActive({
      ...componentIsActive,
      loaderApple: false,
      tutorialStatic: true,
    })
  }

  useEffect(() => {
    const progressbarInner = document.querySelector(
      ".loader-apple__progressbar-inner"
    )
    let width = 1
    const startDelay = 3000
    const delay = 150

    setTimeout(frame, startDelay)

    function frame() {
      if (width >= 101) {
        setIsDownloaded(true)
      } else {
        width++
        progressbarInner.style.width = `${width}%`
        setTimeout(frame, delay)
      }
    }
  }, [componentIsActive])

  const progressbarTpl = (
    <div className="loader-apple__progressbar">
      <div className="loader-apple__progressbar-inner">&nbsp;</div>
    </div>
  )

  const btnTpl = (
    <Button className="loader-apple__btn" skin="red" onClick={onClickHandler}>
      Начать получать деньги
    </Button>
  )

  return (
    <div className="loader-apple">
      <div className="loader-apple__logo-wrapper">
        <img
          className="loader-apple__logo"
          src={isDownloaded ? IconReady : appleLogo}
          alt="Loader status"
        />
      </div>
      {isDownloaded ? null : progressbarTpl}
      <div className="loader-apple__status">
        {isDownloaded ? "Загрузка завершена" : "Загрузка Appbonus"}
      </div>
      {isDownloaded ? btnTpl : null}
    </div>
  )
}
