import React, { useContext, useEffect } from "react"
import { ComponentIsActiveContext } from "../../context/context"
import logEvent from "../../utils/logEvent"
import "normalize.css/normalize.css"
import "./Layout.css"
import SEO from "../SEO/SEO"
import SVGSprite from "../SVGSprite/SVGSprite"
import Appstore from "../../components/Appstore/Appstore"
import Loader from "../../components/Loader/Loader"
import LoaderApple from "../../components/LoaderApple/LoaderApple"
import Tutorial from "../../components/Tutorial/Tutorial"
import TutorialStatic from "../../components/TutorialStatic/TutorialStatic"
import TutorialTestflight from "../Tutorial/TutorialTestflight"
import Scripts from "../../components/Scripts/Scripts"

export default ({
  aso_images,
  age,
  app_icon,
  category,
  description,
  developer_full,
  developer_short,
  download_link,
  facebook_pixel,
  footer,
  google_analytics,
  google_optimize,
  gtm,
  gtm_special_event,
  lang,
  position,
  rating,
  rating_chart,
  reviews,
  reviews_num_short,
  reviews_num_full,
  scripts,
  size,
  subtitle,
  title,
  tutorial,
  type,
  url,
}) => {
  const { componentIsActive, setComponentIsActive } = useContext(
    ComponentIsActiveContext
  )

  useEffect(() => {
    logEvent("page view", { url }, gtm_special_event)

    if (type === "testflight_dynamic") {
      setComponentIsActive({
        ...componentIsActive,
        loader: true,
      })
    } else {
      setComponentIsActive({
        ...componentIsActive,
        appstore: true,
      })
    }
  }, [])

  const appstoreTpl = (
    <Appstore
      aso_images={aso_images}
      age={age}
      app_icon={app_icon}
      category={category}
      description={description}
      developer_full={developer_full}
      developer_short={developer_short}
      download_link={download_link}
      footer={footer}
      gtm_special_event={gtm_special_event}
      lang={lang}
      position={position}
      rating={rating}
      rating_chart={rating_chart}
      reviews={reviews}
      reviews_num_short={reviews_num_short}
      reviews_num_full={reviews_num_full}
      size={size}
      subtitle={subtitle}
      title={title}
      type={type}
      url={url}
    />
  )

  const tutorialTpl = <Tutorial tutorial={tutorial} />

  const tutorialStaticTpl = (
    <TutorialStatic
      download_link={download_link}
      gtm_special_event={gtm_special_event}
      url={url}
    />
  )

  const tutorialTestflightTpl = (
    <TutorialTestflight
      app_icon={app_icon}
      download_link={download_link}
      gtm_special_event={gtm_special_event}
      type={type}
      url={url}
    />
  )

  const LoaderAppleTpl = (
    <LoaderApple gtm_special_event={gtm_special_event} url={url} />
  )

  const LoaderTpl = <Loader />

  return (
    <>
      <SEO
        lang={lang}
        facebook_pixel={facebook_pixel}
        google_analytics={google_analytics}
        google_optimize={google_optimize}
        gtm={gtm}
        title={title}
        type={type}
      />
      <SVGSprite />
      {componentIsActive.appstore ? appstoreTpl : null}
      {componentIsActive.tutorial ? tutorialTpl : null}
      {componentIsActive.tutorialStatic ? tutorialStaticTpl : null}
      {componentIsActive.tutorialTestflight ? tutorialTestflightTpl : null}
      {componentIsActive.loaderApple ? LoaderAppleTpl : null}
      {componentIsActive.loader ? LoaderTpl : null}
      {scripts && <Scripts data={scripts} />}
    </>
  )
}
