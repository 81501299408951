import React from "react"
import "./SupportedDevice.css"

export default () => {
  return (
    <div className="device">
      <div className="device__left">
        <svg className="device-icon">
          <use xlinkHref="#SmartphoneIcon" />
        </svg>
        <span>iPhone</span>
      </div>
      <div className="device__right">
        <svg className="down-icon">
          <use xlinkHref="#DownIcon" />
        </svg>
      </div>
    </div>
  )
}
