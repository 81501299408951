import React, { useContext } from "react"
import {
  ComponentIsActiveContext,
  TestFlightDataContext,
} from "../../context/context"
import logEvent from "../../utils/logEvent"
import sendToGA from "../../utils/sendToGA"
import createAndClickLink from "../../utils/createLinkAndClick"
import { intl } from "../../intl/intl"
import Button from "../Button/Button"
import ButtonExtLink from "../Button/ButtonExtLink"
import "./Header.css"

export default ({
  app_icon,
  download_link,
  gtm_special_event,
  lang,
  subtitle,
  title,
  type,
  url,
}) => {
  const { componentIsActive, setComponentIsActive } = useContext(
    ComponentIsActiveContext
  )
  const { testFlightDataContext } = useContext(TestFlightDataContext)

  const chooseBtn = type => {
    switch (type) {
      case "aso":
        return (
          <ButtonExtLink
            download_link={download_link}
            onClick={onClickHandlerAso}
            className="cta-button"
          >
            {intl[lang].downloadApp}
          </ButtonExtLink>
        )
      case "appbonus":
        return (
          <Button onClick={onClickHandlerAppbonus}>
            {intl[lang].downloadApp}
          </Button>
        )
      case "appbonus_tutorial_static":
        return (
          <Button onClick={onClickHandlerAppbonusTutorialStatic}>
            {intl[lang].downloadApp}
          </Button>
        )
      case "testflight_dynamic":
      case "testflight_static":
        return (
          <Button onClick={onClickHandlerTestflight}>
            {intl[lang].downloadApp}
          </Button>
        )
      default:
        return (
          <ButtonExtLink
            download_link={download_link}
            onClick={onClickHandlerAso}
          >
            {intl[lang].downloadApp}
          </ButtonExtLink>
        )
    }
  }
  const onClickHandlerAso = () => {
    logEvent("click", { url, button: "download-app" }, gtm_special_event)
    sendToGA("click", "get")
  }
  const onClickHandlerAppbonus = () => {
    logEvent("click", { url, button: "download-app" }, gtm_special_event)

    createAndClickLink(download_link)
    setComponentIsActive({
      ...componentIsActive,
      loaderApple: true,
      appstore: false,
    })
  }
  const onClickHandlerAppbonusTutorialStatic = () => {
    logEvent("click", { url, button: "download-app" }, gtm_special_event)

    createAndClickLink(download_link)
    setComponentIsActive({
      ...componentIsActive,
      tutorialStatic: true,
      appstore: false,
    })
  }
  const onClickHandlerTestflight = () => {
    logEvent("click", { url, button: "download-app" }, gtm_special_event)

    setComponentIsActive({
      ...componentIsActive,
      tutorialTestflight: true,
      appstore: false,
    })
  }

  const onClickShare = () => {
    logEvent("click", { url, button: "share" }, gtm_special_event)

    navigator.share({
      title,
      url: window.location.href,
    })
  }

  return (
    <header className="header">
      <div className="header__icon">
        <img
          className="header__icon-item"
          src={app_icon.publicURL}
          alt="App icon"
        />
      </div>
      <div className="header__desc">
        <div className="header__desc-top">
          <div className="header__title">
            {type === "testflight_dynamic"
              ? `${testFlightDataContext.name} - мобильный заработок`
              : title}
          </div>
          <div className="header__dev">{subtitle}</div>
        </div>
        <div className="header__desc-bottom">
          {chooseBtn(type)}
          <button
            type="button"
            className="btn btn--transparent btn--share"
            onClick={onClickShare}
          >
            <svg className="share-icon">
              <use xlinkHref="#ShareIcon" />
            </svg>
          </button>
        </div>
      </div>
    </header>
  )
}
