import React, { useContext, useEffect } from "react"
import { ComponentIsActiveContext, TestFlightDataContext } from "../../context/context"
import "./Loader.css"

export default () => {
  const { componentIsActive, setComponentIsActive } = useContext(ComponentIsActiveContext);
  const { setTestFlightDataContext } = useContext(TestFlightDataContext);

  useEffect(() => {
    fetch('https://www.appbonus.ru/api_app_url')
      .then(response => response.json())
      .then(data => {
        setTestFlightDataContext({
          testflight_link: data.testflight_link,
          name: data.name,
        })
        setComponentIsActive({
          ...componentIsActive,
          loader: false,
          appstore: true,
        })
      })
  })

  return (
    <div>
      <div className="lds-spinner-wrapper">
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}
