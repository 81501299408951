import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import ReviewUser from "./ReviewUser"
import ReviewDev from "./ReviewDev"
import ReviewFull from "./ReviewFull"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Review.css"

const Review = ({ lang, reviews }) => {
  const [reviewIndex, setReviewIndex] = useState(0)
  const [reviewFullVisible, setReviewFullVisible] = useState(false)
  
  const showFullReview = index => {
    setReviewIndex(index)
    setReviewFullVisible(true)
  }
  const hideFullReview = () => {
    setReviewFullVisible(false)
  }

  useEffect(() => {
    const body = document.body

    const lockBody = () => {
      body.style.top = `-${window.scrollY}px`
      body.style.left = "0"
      body.style.right = "0"
      body.style.position = "fixed"
    }

    const unlockBody = () => {
      const scrollY = body.style.top
      body.style.position = ""
      body.style.top = ""
      body.style.left = ""
      body.style.right = ""
      window.scrollTo(0, parseInt(scrollY || "0") * -1)
    }

    reviewFullVisible ? lockBody() : unlockBody()
  }, [reviewFullVisible])

  return (
    <div className="review">
      <ReviewFull
        data={reviews[reviewIndex]}
        lang={lang}
        visible={reviewFullVisible}
        onClick={hideFullReview}
      />
      <Slider
        dots={false}
        arrows={false}
        slidesToShow={1}
        slidesToScroll={1}
        infinite={false}
        centerMode={true}
        centerPadding={"30px"}
      >
        {reviews.map((item, index) => {
          return (
            <div key={index}>
              <div className="review__item">
                <ReviewUser
                  data={item}
                  lang={lang}
                  currentReviewIndex={index}
                  onClick={showFullReview}
                />
                {item.Review_dev_content && (
                  <ReviewDev
                    data={item}
                    lang={lang}
                    currentReviewIndex={index}
                    onClick={showFullReview}
                  />
                )}
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
export default Review
