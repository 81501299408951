import React from "react"
import "./AsoExamples.css"

const AsoExamples = React.forwardRef(({aso_images}, ref) => {
  return (
    <div className="aso" ref={ref}>
      {
      aso_images.map((item, index) => {
        const imgTpl = (
          <div className="aso__block" key={item.url}>
            <img
              src={item.url}
              alt={`App example ${index}`}
              className="aso__item"
            />
          </div>
        )
        const videoTpl = (
          <div className="aso__block" key={item.url}>
            <video
              autoPlay
              className="aso__item"
              loop
              muted
              playsInline="playsinline"
              src={item.url}
            > </video>
          </div>
        )
        return item.mime === 'video/mp4' ? videoTpl : imgTpl
      })}
    </div>
  )
})

export default AsoExamples
