export default (eventCategory, eventAction) => {
  if (process.env.NODE_ENV !== "development") {
    /* eslint-disable */
    if (window.ga !== undefined) {
      ga("send", "event", eventCategory, eventAction)
      if (window.performance) {
        var timeSincePageLoad = Math.round(performance.now())
        // Пока замер времени используется только для клика по кнопке "Загрузить".
        // Если будем расширять этот функционал, то надо будет передавать значения в параметрах.
        // На данный момент не ясно в каком виде это будет, поэтому захардкодил.
        // timingCategory 'interactions' - название выбрал на своё усмотрение.
        ga("send", "timing", "interactions", "time_to_click", timeSincePageLoad)
      }
    }
    /* eslint-enable */
  } else {
    console.log({
      service: "Google Analytics",
      eventCategory,
      eventAction,
    })
  }
}
