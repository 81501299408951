import React from "react"
import {intl} from '../../intl/intl'
import "./Developer.css"

export default ({ developer_short, lang }) => {
  return (
    <div className="developer">
      <div className="developer__left">
        <div className="developer__name">{developer_short}</div>
        <div className="developer__label">{intl[lang].developer}</div>
      </div>
      <div className="developer__right">
        <svg className="right-icon">
          <use xlinkHref="#RightIcon" />
        </svg>
      </div>
    </div>
  )
}
