import React, { useEffect, useRef } from "react"
import logEvent from "../../utils/logEvent"
import sendToGA from "../../utils/sendToGA"
import "./Appstore.css"
import HeaderAso from "../Header/HeaderAso"
import Stat from "../Stat/Stat"
import AsoExamples from "../AsoExamples/AsoExamples"
import SupportedDevice from "../SupportedDevice/SupportedDevice"
import Description from "../Description/Description"
import Developer from "../Developer/Developer"
import Rating from "../Rating/Rating"
import Review from "../Review/Review"
import Info from "../Info/Info"
import { Footer } from "../Footer/Footer"

export default ({
  aso_images,
  age,
  app_icon,
  category,
  description,
  developer_full,
  developer_short,
  download_link,
  footer,
  gtm_special_event,
  lang,
  position,
  rating,
  rating_chart,
  reviews,
  reviews_num_short,
  reviews_num_full,
  size,
  subtitle,
  title,
  type,
  url,
}) => {
  const asoEl = useRef(null)
  const lastEl = useRef(null) // last element on the page

  let rating_formatted = rating.toString()
  if (rating_formatted.length === 1) {
    rating_formatted = `${rating},0`
  } else {
    rating_formatted = rating_formatted.replace(".", ",")
  }

  useEffect(() => {
    // Однократная прокрутка до блока со скриншотами
    const intersectionObserverScreenshotsBlock = new IntersectionObserver(
      entries => {
        if (entries[0].intersectionRatio <= 0) return
        logEvent("view", { url, element: "ASO block" }, gtm_special_event)
        intersectionObserverScreenshotsBlock.disconnect()
      },
      { threshold: 1 }
    )
    intersectionObserverScreenshotsBlock.observe(asoEl.current)

    // Однократная прокрутка всей страницы до конца
    const intersectionObserverLastElem = new IntersectionObserver(
      entries => {
        if (entries[0].intersectionRatio <= 0) return
        logEvent("view", { url, element: "Scroll down the entire page" }, gtm_special_event)
        sendToGA("scroll", "vertical")
        intersectionObserverLastElem.disconnect()
      },
      { threshold: 1 }
    )
    intersectionObserverLastElem.observe(lastEl.current)

    // Прокрутка карусели скриншотов минимум 1 раз
    const viewAsoElements = () => {
      logEvent("view", { url, element: "ASO items" }, gtm_special_event)
      sendToGA("scroll", "horizontal")
      asoEl.current.removeEventListener("scroll", viewAsoElements)
    }
    asoEl.current.addEventListener("scroll", viewAsoElements)
  }, [])

  const footerTpl = <Footer developer_full={developer_full} />

  return (
    <>
      <div className="container">
        <HeaderAso
          app_icon={app_icon}
          download_link={download_link}
          gtm_special_event={gtm_special_event}
          lang={lang}
          subtitle={subtitle}
          title={title}
          type={type}
          url={url}
        />
      </div>
      <Stat
        age={age}
        category={category}
        developer_short={developer_short}
        lang={lang}
        position={position}
        rating={rating_formatted}
        reviews_num_short={reviews_num_short}
        size={size}
      />
      <AsoExamples aso_images={aso_images} ref={asoEl} />
      <div className="container">
        <SupportedDevice />
        <Description description={description} lang={lang} />
        <Developer developer_short={developer_short} lang={lang} />
        <Rating
          lang={lang}
          rating={rating_formatted}
          rating_chart={rating_chart}
          reviews_num_full={reviews_num_full}
        />
      </div>
      <Review lang={lang} reviews={reviews} />
      <div className="container" ref={lastEl}>
        <Info
          age={age}
          category={category}
          developer_full={developer_full}
          lang={lang}
          size={size}
        />
      </div>
      {footer ? footerTpl : null}
    </>
  )
}
