import React from "react"
import clsx from "clsx"
import { intl } from "../../intl/intl"

const ReviewFull = ({ data, lang, visible, onClick }) => {
  const stars = [...Array(5)] // ToDo: rework with real rating

  return (
    <div className={clsx("review__full", { "review__full--visible": visible })}>
      <button className="review__back" onClick={onClick}>
        <svg className="review__back-icon">
          <use xlinkHref="#backIcon" />
        </svg>
        <span>{intl[lang].back}</span>
      </button>
      <div className="container review__full-container">
        <h2 className="review__full-title">{intl[lang].rating.title}</h2>
        <div className="review__item review__full-item">
          <div className="review__head">
            <div className="review__head-row review__head-row--1">
              <div className="review__title">{data.Review_title}</div>
              <div className="review__date">{data.Review_user_date}</div>
            </div>
            <div className="review__head-row review__head-row--2">
              <div className="review__stars">
                {stars.map((item, index) => (
                  <svg className="star-icon" key={index}>
                    <use xlinkHref="#StarIcon" />
                  </svg>
                ))}
              </div>
              <div className="review__user">{data.Review_user}</div>
            </div>
          </div>
          <div className="review__content review__content--user review__content--no-line-clamp">
            {data.Review_user_content}
          </div>
          {data.Review_dev_content && (
            <>
              <div className="review__head review__head--dev">
                <div className="review__head-row review__head-row--1">
                  <div className="review__title">
                    {intl[lang].developerResponse}
                  </div>
                  <div className="review__date">{data.Review_dev_date}</div>
                </div>
              </div>
              <div className="review__content review__content--dev review__content--no-line-clamp">
                {data.Review_dev_content}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReviewFull
