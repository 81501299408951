import React, { useContext } from "react"
import platform from "platform"
import { ComponentIsActiveContext } from "../../context/context"
import logEvent from "../../utils/logEvent"
import { mobileProvisionUrl } from "../../utils/siteSettings"

import "./TutorialStatic.css"

import logoAppbonus from "../../assets/images/TutorialStatic/logo-appbonus.png"
import iconAppbonus from "../../assets/images/TutorialStatic/icon-appbonus.png"
import iconClose from "../../assets/images/TutorialStatic/icon-close.svg"
import stepDownload from "../../assets/images/TutorialStatic/img-step-1.png"
import stepOld1 from "../../assets/images/TutorialStatic/tutorial-ios-old-1.jpg"
import stepOld2 from "../../assets/images/TutorialStatic/tutorial-ios-old-2.jpg"
import stepOld3 from "../../assets/images/TutorialStatic/tutorial-ios-old-3.jpg"
import stepOld4 from "../../assets/images/TutorialStatic/tutorial-ios-old-4.jpg"
import stepIos15_1 from "../../assets/images/TutorialStatic/tutorial-ios15-1.jpg"
import stepIos15_2 from "../../assets/images/TutorialStatic/tutorial-ios15-2.png"
import stepIos15_3 from "../../assets/images/TutorialStatic/tutorial-ios15-3.png"
import stepIos15_4 from "../../assets/images/TutorialStatic/tutorial-ios15-4.png"

const TutorialStatic = ({ download_link, gtm_special_event, url }) => {
  const { componentIsActive, setComponentIsActive } = useContext(
    ComponentIsActiveContext
  )

  const clickDownloadHandler = () => {
    logEvent("click", { url, button: "download-app-instr" }, gtm_special_event)
  }

  const clickSettingsHandlerLink = () => {
    logEvent("click", { url, button: "settings-instr-link" }, gtm_special_event)
  }

  const clickSettingsHandlerBtn = () => {
    logEvent("click", { url, button: "settings-instr-btn" }, gtm_special_event)
  }

  // t7
  const clickLaunchHandler = () => {
    logEvent("click", { url, button: "launch-instr" }, gtm_special_event)
  }

  const clickCloseHandler = () => {
    setComponentIsActive({
      ...componentIsActive,
      appstore: true,
      tutorialStatic: false,
    })
  }

  const currentPlatformVersion = parseInt(platform.version)

  const iosOldTpl = (
    <>
      <div className="block">
        <p className="block__label">
          Зайдите в&nbsp;
          <b>
            <a
              className="block__link"
              href={mobileProvisionUrl}
              onClick={clickSettingsHandlerLink}
            >
              настройки
            </a>
          </b>{" "}
          в раздел <b>"Основные"</b>
        </p>
        <div className="block__img">
          <img className="block__img-item" src={stepOld1} />
        </div>
      </div>
      <div className="block">
        <p className="block__label">
          Пролистайте вниз и перейдите в раздел <b>"Управление устройством"</b>
        </p>
        <div className="block__img">
          <img className="block__img-item" src={stepOld2} />
        </div>
      </div>
      <div className="block">
        <p className="block__label">
          Найдите пункт <b>"MOBAILDEVELOPMENT, ООО"</b>
        </p>
        <div className="block__img">
          <img className="block__img-item" src={stepOld3} />
        </div>
      </div>
      <div className="block">
        <p className="block__label">
          Нажмите<b>"Доверять"</b>
        </p>
        <div className="block__img">
          <img className="block__img-item" src={stepOld4} />
        </div>
      </div>
    </>
  )

  const ios15Tpl = (
    <>
      <div className="block">
        <p className="block__label">
          Зайдите в&nbsp;
          <b>
            <a
              className="block__link"
              href={mobileProvisionUrl}
              onClick={clickSettingsHandlerLink}
            >
              "Настройки"
            </a>
          </b>{" "}
          в&nbsp;раздел <b>"Основные"</b>, пролистайте вниз и перейдите в раздел{" "}
          <b>"VPN и управление устройством"</b>
        </p>
        <div className="block__img">
          <img className="block__img-item" src={stepIos15_1} />
        </div>
      </div>
      <div className="block">
        <p className="block__label">
          Найдите пункт <b>"MOBAILDEVELOPMENT, ООО"</b>
        </p>
        <div className="block__img">
          <img className="block__img-item" src={stepIos15_2} />
        </div>
      </div>
      <div className="block">
        <p className="block__label">
          Нажмите <b>"Доверять"</b>
        </p>
        <div className="block__img">
          <img className="block__img-item" src={stepIos15_3} />
        </div>
      </div>
      <div className="block">
        <p className="block__label">
          Нажмите <b>"Доверять"</b> в открывшемся всплывающем окне
        </p>
        <div className="block__img">
          <img className="block__img-item" src={stepIos15_4} />
        </div>
      </div>
    </>
  )

  return (
    <div className="tutorial-static">
      <div className="header">
        <img
          width="165px"
          height="36px"
          alt="Appbonus logo"
          src={logoAppbonus}
        />
        <button className="close" onClick={clickCloseHandler}>
          <img width="15px" height="15px" alt="Close icon" src={iconClose} />
        </button>
      </div>
      <h1 className="title">
        Следуйте инструкции, чтобы начать получать деньги
      </h1>
      <h2 className="subtitle">Отключите VPN перед установкой</h2>
      <a
        className="button button--red button--header"
        href={download_link}
        onClick={clickDownloadHandler}
      >
        Загрузить
      </a>
      <div className="blocks">
        <div className="block">
          <p className="block__label">Дождитесь полной загрузки приложения</p>
          <div className="block__img">
            <img className="block__img-item" src={stepDownload} />
          </div>
        </div>
        {currentPlatformVersion >= 15 ? ios15Tpl : iosOldTpl}
        <div className="block">
          <a
            className="button button--red"
            href={mobileProvisionUrl}
            onClick={clickSettingsHandlerBtn}
          >
            Перейти в настройки
          </a>
        </div>
        <div className="block">
          <p className="block__title">
            Выполнив <span>инструкцию</span>,<br /> начните получать{" "}
            <span>деньги</span>,<br /> запустив приложение
          </p>
        </div>
        <div className="block">
          <img className="block__logo" src={iconAppbonus} />
        </div>
        <div className="block">
          <a
            className="button button--red"
            href="appbonus://"
            onClick={clickLaunchHandler}
          >
            Запустить
          </a>
        </div>
      </div>
    </div>
  )
}

export default TutorialStatic
