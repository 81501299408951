import React from "react"
import clsx from 'clsx'
import "./Button.css"

export default ({ children, className, skin, onClick }) => {
  return (
    <button
      className={clsx("btn", skin ? `btn--${skin}` : "btn--blue", className)}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  )
}
