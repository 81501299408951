import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { TestFlightDataContext } from "../../context/context"
import useGTM from "../../hooks/useGTM"

export default ({ facebook_pixel, lang, google_analytics, google_optimize, gtm, title, type }) => {
  const { testFlightDataContext } = useContext(TestFlightDataContext)

  const google_analytics_tag = {
    type: "text/javascript",
    innerHTML: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      ga('create', '${google_analytics}', 'auto');
      ga('send', 'pageview');`
  }

  const google_optimize_tag = {
    type: "text/javascript",
    async: true,
    src: `https://www.googleoptimize.com/optimize.js?id=${google_optimize}`,
  }

  const facebook_pixel_tag = {
    type: "text/javascript",
    innerHTML: `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${facebook_pixel}');
    fbq('track', 'PageView');`
  }

  const gtm_tag = {
    type: "text/javascript",
    innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtm}');`,
  }

  let script_tags = [
    google_analytics ? google_analytics_tag : null,
    google_optimize ? google_optimize_tag : null,
    facebook_pixel ? facebook_pixel_tag : null,
    gtm ? gtm_tag : null,
  ]
  script_tags = script_tags.filter(x => x)

  const helmetProdTpl = (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      script={script_tags}
      title={
        type === "testflight_dynamic"
          ? `${testFlightDataContext.name} - мобильный заработок`
          : title
      }
    />
  )
  const helmetDevTpl = (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={
        type === "testflight_dynamic"
          ? `${testFlightDataContext.name} - мобильный заработок`
          : title
      }
    />
  )

  useGTM(gtm)

  return process.env.NODE_ENV === "development" ? helmetDevTpl : helmetProdTpl
}
