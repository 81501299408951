import React, { useContext, useState } from "react"
import clsx from "clsx"
import { TestFlightDataContext } from "../../context/context"
import logEvent from "../../utils/logEvent"
import ButtonExtLink from "../Button/ButtonExtLink"
import "./Tutorial.css"
import iconTestflight from "../../assets/images/icon-testflight.png"
import iconMobileEarn from "../../assets/images/mobile-earn_app--icon.png"

const TutorialTestflight = ({ app_icon, download_link, gtm_special_event, type, url }) => {
  const { testFlightDataContext } = useContext(TestFlightDataContext)
  const [testflightIsReady, setTestflightIsReady] = useState(false)
  const [appbonusIsReady, setAppbonusIsReady] = useState(false)

  const onClickHandlerTestflight = () => {
    logEvent('click', {url, button: 'download testflight'}, gtm_special_event)
    setTestflightIsReady(true)
  }
  const onClickHandlerAppbonus = () => {
    logEvent('click', {url, button: 'download appbonus'}, gtm_special_event)
    setTimeout(() => setAppbonusIsReady(true), 5000)
  }
  const onClickHandlerOpenApp = () => {
    logEvent('click', {url, button: 'open appbonus'}, gtm_special_event)
  }

  const setAppIcon = () => type === "testflight_dynamic" ? iconMobileEarn : app_icon.publicURL

  const setAppName = () => {
    return type === "testflight_dynamic"
      ? testFlightDataContext.name
      : url === "time4work"
      ? "Time4Work"
      : "Appbonus"
  }

  const setAppLink = () => type === "testflight_dynamic" ? testFlightDataContext.testflight_link : download_link

  return (
    <div className="tutorial tutorial--testflight">
      <div className="tutorial__header">
        <svg className="tutorial__header-icon">
          <use xlinkHref="#LogoApple" />
        </svg>
        <span className="tutorial__header-label">Developer</span>
      </div>
      <h2 className="tutorial__title">
        Следуйте инструкции, <br/>
        чтобы начать <br/>
        получать деньги
      </h2>
      <div className="tutorial__block">
        <div className="tutorial__label">
          <p><b>01.</b></p>
          <p>Вам нужно загрузить</p>
        </div>
        <div className="tutorial__image">
          <img
            className="tutorial__image-item"
            src={iconTestflight}
            alt={`Tutorial step 1`}
          />
        </div>
        <div className="tutorial__label">
          <p className="tutorial__label--app">Test Flight</p>
          <p className="tutorial__label--dev">Apple</p>
        </div>
        <div className="tutorial__action">
          <ButtonExtLink
            download_link="https://itunes.apple.com/ru/app/testflight/id899247664?mt=8"
            onClick={onClickHandlerTestflight}
          >
            Загрузить
          </ButtonExtLink>
        </div>
      </div>
      <div className="tutorial__block">
        <div className="tutorial__label">
          <p><b>02.</b></p>
          <p>После загрузки Test Flight, установите</p>
        </div>
        <div className="tutorial__image">
          <img
            className="tutorial__image-item"
            src={setAppIcon()}
            alt={`Tutorial step 2`}
          />
        </div>
        <div className="tutorial__label">
          <p className="tutorial__label--app">{setAppName()}</p>
          <p className="tutorial__label--dev">Juicysoft</p>
        </div>
        <div className="tutorial__action">
          <ButtonExtLink
            className={clsx({'btn--disabled': !testflightIsReady})}
            download_link={testflightIsReady ? setAppLink() : null}
            onClick={onClickHandlerAppbonus}
            target='_blank'
          >
            Установить
          </ButtonExtLink>
        </div>
      </div>
      <div className="tutorial__block">
        <div className="tutorial__label">
          <p><b>03.</b></p>
          <p>После установки, откройте {setAppName()} и начинайте зарабатывать</p>
        </div>
        <div className="tutorial__action">
          <ButtonExtLink
            className={clsx({'btn--disabled': !appbonusIsReady})}
            download_link={appbonusIsReady ? "appbonus://" : null}
            onClick={onClickHandlerOpenApp}
          >
            Открыть
          </ButtonExtLink>
        </div>
        <div className="tutorial__note">
          <p>
            {!appbonusIsReady ? 'Следуйте инструкции, если вы сделаете все последовательно, эта кнопка станет активной' : null}
          </p>
        </div>
      </div>
    </div>
  )
}
export default TutorialTestflight
