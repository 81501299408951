import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import clsx from 'clsx'
import {intl} from '../../intl/intl'
import "./Description.css"

const Description = ({ description, lang }) => {
  const [collapsed, setIsCollapsed] = useState(true)
  const btnTpl = (
    <span
      className="description__more"
      onClick={() => setIsCollapsed(false)}
    >
        {intl[lang].more}
      </span>
  )

  return (
    <div className={clsx('description', {'collapsed': collapsed})}>
      <ReactMarkdown source={description} />
      {collapsed ? btnTpl : null}
    </div>
  )
}
export default Description
