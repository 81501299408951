import React, { createContext, useState } from "react"

export const ComponentIsActiveContext = createContext()
export const ComponentIsActiveContextProvider = ({children}) => {
  const [componentIsActive, setComponentIsActive] = useState({
    appstore: false,
    loader: false,
    loaderApple: false,
    tutorial: false,
    tutorialTestflight: false,
    tutorialStatic: false,
  })
  return (
    <ComponentIsActiveContext.Provider value={{componentIsActive, setComponentIsActive}}>
      {children}
    </ComponentIsActiveContext.Provider>
  )
}

export const TestFlightDataContext = createContext()
export const TestFlightDataContextProvider = ({children}) => {
  const [testFlightDataContext, setTestFlightDataContext] = useState({
    testflight_link: '',
    name: '',
  })
  return (
    <TestFlightDataContext.Provider value={{testFlightDataContext, setTestFlightDataContext}}>
      {children}
    </TestFlightDataContext.Provider>
  )
}
