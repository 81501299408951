import React from "react"
import clsx from 'clsx'
import "./Button.css"

export default ({ children, className, skin, onClick, download_link, target }) => {
  return (
    <a
      className={clsx("btn", skin ? `btn--${skin}` : "btn--blue", className)}
      onClick={onClick}
      href={download_link}
      target={target || '_self'}
    >
      {children}
    </a>
  )
}
