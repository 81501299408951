import React from "react"
import {intl} from '../../intl/intl'
import "./Info.css"

export default ({ age, category, developer_full, lang, size }) => {
  return (
    <div className="info">
      <h2 className="info__title">{intl[lang].info.title}</h2>
      <div className="info__row">
        <span className="info__label">{intl[lang].info.provider}</span>
        <span className="info__val">{developer_full}</span>
      </div>
      <div className="info__row">
        <span className="info__label">{intl[lang].info.size}</span>
        <span className="info__val">{size}&nbsp;<span className="text-uppercase">{intl[lang].info.sizeUnit}</span></span>
      </div>
      <div className="info__row">
        <span className="info__label">{intl[lang].info.category}</span>
        <span className="info__val">{category}</span>
      </div>
      <div className="info__row">
        <span className="info__label">{intl[lang].info.compatibility}</span>
        <span className="info__val">{intl[lang].info.worksOnDevice}</span>
      </div>
      <div className="info__row">
        <span className="info__label">{intl[lang].info.languages}</span>
        <span className="info__val">{intl[lang].info.currentLang}</span>
      </div>
      <div className="info__row">
        <span className="info__label">{intl[lang].info.age}</span>
        <span className="info__val">{age}+</span>
      </div>
    </div>
  )
}
