import React from "react"
import {intl} from '../../intl/intl'
import "./Rating.css"

export default ({ lang, rating, rating_chart, reviews_num_full }) => {
  const chart = [
    { stars: 5, width: rating_chart.five_stars },
    { stars: 4, width: rating_chart.four_stars },
    { stars: 3, width: rating_chart.three_stars },
    { stars: 2, width: rating_chart.two_stars },
    { stars: 1, width: rating_chart.one_star },
  ]
  const starsTpl = starsQty => {
    const stars = []
    for (let i = 0; i < starsQty; i++) {
      stars[i] = (
        <svg className="star-icon" key={i}>
          <use xlinkHref="#StarIcon" />
        </svg>
      )
    }
    return stars
  }

  return (
    <div className="rating">
      <div className="rating__row rating__row--top">
        <div className="rating__title">{intl[lang].rating.title}</div>
        <div className="rating__all">{intl[lang].rating.all}</div>
      </div>
      <div className="rating__row rating__row--middle">
        <div className="rating__num">
          <div className="rating__num-val">{rating}</div>
          <div className="rating__num-label">{intl[lang].rating.outOf}</div>
        </div>
        <div className="rating__graph">
          <div className="rating__graph-items">
            {chart.map((item, index) => {
              return (
                <div className="rating__graph-item" key={index}>
                  <div className="rating__graph-stars">
                    {starsTpl(item.stars)}
                  </div>
                  <div className="rating__graph-bar">
                    <div
                      className="rating__graph-bar-val"
                      style={{ width: `${item.width}%` }}
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="rating__graph-label">{reviews_num_full}</div>
        </div>
      </div>
    </div>
  )
}
