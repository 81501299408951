import React from "react"
import { intl } from "../../intl/intl"
import "./Stat.css"

export default ({
  age,
  category,
  developer_short,
  lang,
  position,
  rating,
  reviews_num_short,
  size,
}) => {
  return (
    <div className="stat">
      <div className="stat__col">
        <div className="stat__label">{reviews_num_short}</div>
        <div className="stat__val">{rating}</div>
        <div className="stat__sub">
          <div className="stat__stars">
            <svg className="star-icon">
              <use xlinkHref="#StarIcon" />
            </svg>
            <svg className="star-icon">
              <use xlinkHref="#StarIcon" />
            </svg>
            <svg className="star-icon">
              <use xlinkHref="#StarIcon" />
            </svg>
            <svg className="star-icon">
              <use xlinkHref="#StarIcon" />
            </svg>
            <svg className="star-icon">
              <use xlinkHref="#StarIcon" />
            </svg>
          </div>
        </div>
      </div>
      <div className="stat__col">
        <div className="stat__label">{intl[lang].age}</div>
        <div className="stat__val">{age}+</div>
      </div>
      <div className="stat__col">
        <div className="stat__label">{intl[lang].ratingLabel}</div>
        <div className="stat__val">#{position}</div>
        <div className="stat__sub">{category}</div>
      </div>
      <div className="stat__col">
        <div className="stat__label">{intl[lang].developer}</div>
        <div className="stat__val">
          <svg className="stat__dev-icon">
            <use xlinkHref="#DevIcon" />
          </svg>
        </div>
        <div className="stat__sub">{developer_short}</div>
      </div>
      <div className="stat__col">
        <div className="stat__label">{intl[lang].langLabel}</div>
        <div className="stat__val text-uppercase">{lang}</div>
        <div className="stat__sub text-lowercase">
          {intl[lang].info.currentLang}
        </div>
      </div>
      <div className="stat__col">
        <div className="stat__label">{intl[lang].info.size}</div>
        <div className="stat__val">{size}</div>
        <div className="stat__sub text-uppercase">
          {intl[lang].info.sizeUnit}
        </div>
      </div>
    </div>
  )
}
