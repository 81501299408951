import React, { useEffect, useRef, useState } from "react"
import { intl } from "../../intl/intl"

const ReviewDev = ({ data, lang, currentReviewIndex, onClick }) => {
  const stars = [...Array(5)] // ToDo: rework with real rating
  const textContainer = useRef(null)
  const maxLines = 3
  const [numberOfLines, setNumberOfLines] = useState(0)

  const moreBtnTpl = (
    <span className="review__more" onClick={() => onClick(currentReviewIndex)}>
      {intl[lang].more}
    </span>
  )

  useEffect(() => {
    setNumberOfLines(textContainer.current.getClientRects().length)
  }, [])

  return (
    <>
      <div className="review__head">
        <div className="review__head-row review__head-row--1">
          <div className="review__title">{data.Review_title}</div>
          <div className="review__date">{data.Review_user_date}</div>
        </div>
        <div className="review__head-row review__head-row--2">
          <div className="review__stars">
            {stars.map((item, index) => (
              <svg className="star-icon" key={index}>
                <use xlinkHref="#StarIcon" />
              </svg>
            ))}
          </div>
          <div className="review__user">{data.Review_user}</div>
        </div>
      </div>
      <div className="review__content review__content--user">
        {/* Inline container for counting lines */}
        <span ref={textContainer}>{data.Review_user_content}</span>
        {numberOfLines > maxLines && moreBtnTpl}
      </div>
    </>
  )
}

export default ReviewDev
